import React from "react";

import {Navbar, NavbarBrand, NavbarContent, NavbarItem, Link, Button} from "@nextui-org/react";
import logo from "../assets/images/lo.png"

export default function AdminNav() {

    const logout = () => {
        localStorage.removeItem("token");
        window.location.href = "/admin";
    }
  return (
    <Navbar>
      <NavbarBrand>
        <img src={logo} />
      </NavbarBrand>

      <NavbarContent justify="end">
        <NavbarItem>
          <Button as={Link} color="danger" href="#" variant="flat" onClick={logout}>
            Log Out
          </Button>
        </NavbarItem>
      </NavbarContent>
    </Navbar>
  );
}
