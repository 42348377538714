import { Component, Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import FooterTwo from "../component/layout/footer-2";
import HeaderThree from "../component/layout/header-3";
import PageHeaderTwo from "../component/layout/pageheader-2";
import CourseSideDetail from "../component/sidebar/course-detail";

const CourseSingle = () => {

    const [selectedCourse, setSelectedCourse] = useState(null);

    useEffect(() => {
        console.log("setSelectedCourse");
        const courseData = localStorage.getItem('selectedCourse');
        try {
            if (courseData) {
            const parsedData = JSON.parse(courseData);
            setSelectedCourse(parsedData); // Assuming you have a state setter function like 'setSelectedCourse'
            }
        } catch (error) {
            // Handle any parsing errors here
            console.log('Error parsing course data from localStorage:');
        }
      }, []);

    return (
        <Fragment>
            <HeaderThree />
            {selectedCourse ? (
            <>
            <PageHeaderTwo cate={selectedCourse.cate}/>
            <div className="course-single-section padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                        {selectedCourse ? (
                                                <>
                                                    {/* Add more course details here */}
                                                    <h3>Course Overview</h3>
                                            <p>{selectedCourse.description}</p>
                                            <h4>What You'll Learn in This Course:</h4>
                                            {selectedCourse.whatUlearn ? (
                                                <>
                                                    <ul className="lab-ul">
                                                        {selectedCourse.whatUlearn.map((item, index) => (
                                                            <li key={index}><i className="icofont-tick-mark"></i>{item}</li>
                                                        ))}
                                                    </ul>
                                                </>
                                            ) : (
                                                <p>No course data found.</p>
                                            )}
                                            <p>{selectedCourse.details}</p>
                                                </>
                                            ) : (
                                                <p>No course data found.</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="course-video">
                                    <div className="course-video-title">
                                        <h4>Course Content</h4>
                                    </div>
                                    <div className="course-video-content">
                                        <div className="accordion" id="accordionExample">
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="accordion01">
                                                    <button className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolist1" aria-expanded="true" aria-controls="videolist1"><span>1.Introduction</span> <span>5lessons, 17:37</span> </button>
                                                </div>
                                                <div id="videolist1" className="accordion-collapse collapse show" aria-labelledby="accordion01" data-bs-parent="#accordionExample">
                                                    <ul className="lab-ul video-item-list">
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">1.1 Welcome to the course 02:30 minutes</div>
                                                            <div className="video-item-icon"><a href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div>
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">1.2 How to set up your photoshop workspace  08:33 minutes</div>
                                                            <div className="video-item-icon"><a href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div>
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">1.3 Essential Photoshop Tools 03:38 minutes</div>
                                                            <div className="video-item-icon"><a href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div>
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">1.4 Finding inspiration 02:30 minutes</div>
                                                            <div className="video-item-icon"><a href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div>
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">1.5 Choosing Your Format 03:48 minutes</div>
                                                            <div className="video-item-icon"><a href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="accordion02">
                                                    <button className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolist2" aria-expanded="true" aria-controls="videolist2"> <span>2.How to Create Mixed Media Art in Adobe Photoshop</span> <span>5 lessons, 52:15</span> </button>
                                                </div>
                                                <div id="videolist2" className="accordion-collapse collapse" aria-labelledby="accordion02" data-bs-parent="#accordionExample">
                                                    <ul className="lab-ul video-item-list">
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">2.1 Using Adjustment Layers 06:20 minutes</div>
                                                            <div className="video-item-icon"><a href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div>
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">2.2 Building the composition 07:33 minutes</div>
                                                            <div className="video-item-icon"><a href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div>
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">2.3 Photoshop Lighting effects 06:30 minutes</div>
                                                            <div className="video-item-icon"><a href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div>
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">2.4 Digital Painting using photoshop brushes 08:34 minutes</div>
                                                            <div className="video-item-icon"><a href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div>
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">2.5 Finalizing the details 10:30 minutes</div>
                                                            <div className="video-item-icon"><a href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <Author /> */}
                                {/* <Comment /> */}
                                {/* <Respond /> */}
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sidebar-part">
                                <CourseSideDetail difficulty={selectedCourse.difficulty} duration={selectedCourse.duration} />
                                {/* <CourseSideCetagory /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterTwo />
            </>
            ) : ( 
                <p className="text-center">No course data found.</p>
            )}
            
        </Fragment>
    );
}

export default CourseSingle;