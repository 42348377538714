


const GoogleMap = () => {
    return (
        <div className="map-area">
            <div className="maps">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3407.49611925224!2d75.56211372433924!3d31.345286024294573!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391a5a84f2ea7a4b%3A0xb887bdc3214b70be!2sGandhi%20Nagar%2C%20Mathura%20Nagar%2C%20Gurdev%20Nagar%2C%20Ram%20Nagar%2C%20Jalandhar%2C%20Punjab%20144008!5e0!3m2!1sen!2sin!4v1696447657502!5m2!1sen!2sin%22"></iframe>
            </div>
        </div>
    );
}
 
export default GoogleMap;