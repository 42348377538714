import React, { useEffect, useState } from "react";
import axios from "axios";
import '../assets/css/disablePage.css';
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
  Tooltip,
  Button,
  Spinner,
  getKeyValue,
} from "@nextui-org/react";
import { DeleteIcon } from "./DeleteIcon";
import { PlusIcon } from "./PlusIcon";
import AdminNav from "./adminNav";
import AddStudPop from "./addStudPop";

export default function App() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const [isOverlayVisible, setOverlayVisible] = useState(false);

  const toggleOverlay = () => {
    setOverlayVisible(!isOverlayVisible);
  };

  const fetchAllStudents = () => {
    axios
      .get("https://certificate-six.vercel.app/students")
      .then((response) => {
        // Assuming the API response is an array of students
        const studentsFromAPI = response.data;

        // Transform the API data into the desired format
        const transformedStudents = studentsFromAPI.map((student, index) => {
          return {
            key: index + 1, // Assign a unique key
            name: student.name,
            hours: student.hours,
            course: student.course,
            certificateNo: student.certificateNo,
            dob: student.dob,
            award: student.award,
          };
        });

        // Set the transformed data in the state
        setUsers(transformedStudents);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  };

  const deleteStudent = async (certificateNo) => {
    toggleOverlay();
    try {
      // Start loading for the specific student being deleted
      const updatedUsers = users.map((user) => {
        if (user.certificateNo === certificateNo) {
          return { ...user, loading: true };
        }
        return user;
      });

      setUsers(updatedUsers);

      await axios.delete(`https://certificate-six.vercel.app/students/delete/${certificateNo}`);

      window.location.reload();
      // Remove the student from the list after deletion
      // const filteredUsers = users.filter((user) => user.certificateNo !== certificateNo);

      // setUsers(filteredUsers);
    } catch (error) {
      console.error("Error deleting student: ", error);
    }
  };

  useEffect(() => {
    fetchAllStudents();
  }, []);

  const [page, setPage] = React.useState(1);
  const rowsPerPage = 4;

  const pages = Math.ceil(users.length / rowsPerPage);

  const items = React.useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;

    return users.slice(start, end);
  }, [page, users]);

  const topContent = React.useMemo(() => {
    return (
      <>
      <AdminNav/>
      <div className="flex flex-col gap-4">
        <div className="flex justify-between gap-3 items-end">
          {/* <Input
            isClearable
            className="w-full sm:max-w-[44%]"
            placeholder="Search by name..."
            startContent={<SearchIcon />}
            value={filterValue}
            onClear={() => onClear()}
            onValueChange={onSearchChange}
          /> */}
          <div className="flex gap-3">
            {/* <Button color="primary" endContent={<PlusIcon />}>
              Add New
            </Button> */}
            <AddStudPop />
          </div>
        </div>
      </div>
      </>
    );
  }, [
    // filterValue,
    // statusFilter,
    // visibleColumns,
    // onRowsPerPageChange,
    users.length,
    // onSearchChange,
    // hasSearchFilter,
  ]);

  return (
    <>
    {isOverlayVisible && (
        <div className="overlay">
          {/* This transparent overlay will cover the entire page */}
        </div>
      )}
      {loading ? (
        <div className="flex w-full justify-center">
          <Spinner />
        </div>
      ) : (
        <div className={isOverlayVisible ? 'content disabled' : 'content'}>
        <Table
          aria-label="Table with client-side pagination"
          topContent={topContent}
          bottomContent={
            <div className="flex w-full justify-center">
              <Pagination
                isCompact
                showControls
                showShadow
                color="secondary"
                page={page}
                total={pages}
                onChange={(page) => setPage(page)}
              />
            </div>
          }
          classNames={{
            wrapper: "min-h-[222px]",
          }}
        >
          <TableHeader>
            <TableColumn key="name">Name</TableColumn>
            <TableColumn key="hours">Hours Completed</TableColumn>
            <TableColumn key="certificateNo">Certificate No.</TableColumn>
            <TableColumn key="course">Course</TableColumn>
            <TableColumn key="dob">Date of Birth</TableColumn>
            <TableColumn key="award">Award Date</TableColumn>
            <TableColumn key="action">Action</TableColumn>
          </TableHeader>
          <TableBody items={items}>
            {(item) => (
              <TableRow key={item.name}>
                {(columnKey) => (
                  <TableCell key={columnKey}>
                    {columnKey === "action" ? (
                      <Tooltip color="danger" content="Delete user">
                        <span
                          className="text-lg text-danger cursor-pointer active:opacity-50"
                          onClick={() => deleteStudent(item.certificateNo)}
                        >
                          {item.loading ? <Spinner size="small" /> : <DeleteIcon />}
                        </span>
                      </Tooltip>
                    ) : (
                      getKeyValue(item, columnKey)
                    )}
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
        </div>
      )}
    </>
  );
}
