import React, { useRef } from 'react';
import emailjs, { send } from '@emailjs/browser';

function ContactEmail () {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_dib1jco', 'template_45tq9fx', form.current, '00Slc5SqhPAbda6UX')
      .then((result) => {
        window.alert("Your message has been sent successfully. We will contact you soon.");
        window.location.reload();
          console.log(result.text);
      }, (error) => {
        window.alert("Your message has not been sent. Please try again.");
          console.log(error.text);
      });
  };

  return (
    <form ref={form} className="contact-form" onSubmit={sendEmail}>
      <div className="form-group">
        <input type="text" name="name" placeholder="Your Name *" />                    
      </div>
      <div className="form-group">
        <input type="email" name="user_email" placeholder="Your Email *"/>
      </div>
      <div className="form-group w-100">
        <textarea name="message" rows="8" type="text" placeholder="Your Message" />
      </div>
      <div className="form-group w-100 text-center">
        <button className="lab-btn" type='submit' value={send}><span>Send Your Message</span></button>
      </div>
      
    </form>
  );
};

export default ContactEmail;