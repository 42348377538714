import React, { useEffect, useState } from "react";
import * as jose from 'jose';
import { Navigate } from 'react-router-dom';
import AdminStudents from "./adminStudents";
import AdminTest from "./adminTest";

const AdminDashboard = () => {
    const [redirectToLogin, setRedirectToLogin] = useState(false);

    async function getAdminDashboard() {
        const jwt = localStorage.getItem("token");
        
        if (jwt) {
            const secret = new TextEncoder().encode(
                'grUTxzPUB3ukHFtdYtN2wFFAMvu3Cn31pC9P7oVbZKo4/D9u7EBR9K5YwxC2wH4u',
            )
              
            try {
                const { payload, protectedHeader } = await jose.jwtVerify(jwt, secret, {
                    issuer: 'urn:example:issuer',
                    audience: 'urn:example:audience',
                });

                if (payload.sub !== "") {
                    // User is authenticated, do nothing
                } else {
                    // User is not authenticated, set state to redirect
                    setRedirectToLogin(true);
                }
            } catch (error) {
                // Handle JWT verification error
                console.error("JWT Verification Error:", error);
                setRedirectToLogin(true);
            }
        } else {
            // JWT token not found, set state to redirect
            setRedirectToLogin(true);
        }
    }

    useEffect(() => {
        getAdminDashboard();
    }, []);

    if (redirectToLogin) {
        // Redirect to the login page
        return <Navigate to="/admin" />;
    }

    return (
        <div>
            {/* <h1>Admin Dashboard</h1>
            <button
                value={"Logout"}
                onClick={() => {
                    localStorage.removeItem("token");
                    window.location.href = "/admin";
                }}
            >
                Logout
            </button> */}
            <AdminTest/>
        </div>
    );
};

export default AdminDashboard;
