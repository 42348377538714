import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './StudentTable.css'; // Import the CSS file
import { Input, Button } from '@nextui-org/react';

function CertificateDetailsTable() {
  const [data, setData] = useState([]);
  const [certificateCode, setCertificateCode] = useState('');
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = (e) => {
    e.preventDefault();
    setIsLoading(true);

    axios.get(`https://certificate-six.vercel.app/students/${certificateCode}`)
      .then((response) => {
        if(response.data.length > 1)
        {
          setIsLoading(false);
          setCertificateCode("");
          setSearchPerformed(true);
        } else {
          setData(response.data);
          setIsLoading(false);
          setCertificateCode("");
          setSearchPerformed(true);
        }
      })
      .catch((error) => {
        console.error('Error fetching data: ', error);
        setIsLoading(false);
        setSearchPerformed(true); // Set searchPerformed to true even on error
      });
  };

  return (
    <div className="table-container">
      <div className="student-search">
        <form onSubmit={handleSearch}>
          {/* <input
            type="text"
            required
            className="student-search-field"
            placeholder="Enter Certificate Code"
            name="certificate_code"
            value={certificateCode}
            onChange={(e) => setCertificateCode(e.target.value)}
          /> */}
          <div className="flex flex-col items-center mb-6 md:mb-0">
            <div className="flex gap-4">
              <Input
                key="outside-left"
                type="text"
                required="true"
                label="Verify Certificate"
                name="certificate_code"
                value={certificateCode}
                onChange={(e) => setCertificateCode(e.target.value)}
                labelPlacement="outside-left"
                placeholder="Enter certificate code"
              />
            </div>
            <div className="mt-4"> {/* Add margin-top for spacing */}
              <Button
                isLoading={isLoading}
                color="success"
                onClick={handleSearch}
                spinner={
                  <svg
                    className="animate-spin h-5 w-5 text-current"
                    fill="none"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      fill="currentColor"
                    />
                  </svg>
                }
              >
                {isLoading ? 'Loading...' : 'Search'}
              </Button>
            </div>
          </div>


          {/* <input type="submit" className="student-search-button" value="Search" name="code_data" /> */}
        </form>
      </div>
      {/* Conditionally render either the table or "Invalid certificate number" */}
      {searchPerformed && (
        <>
          {data.length > 0 && (
            <>
              <h2 className="student-table-caption" style={{ textAlign: 'center' }}>Search Result</h2>
              <table className="rwd-table">
                <tbody>
                  <tr>
                    <th>STUDENT NAME</th>
                    <th>HOURS COMPLETED</th>
                    <th>CERTIFICATION NO</th>
                    <th>COURSE</th>
                    <th>DATE OF BIRTH</th>
                    <th>AWARD DATE</th>
                  </tr>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td data-th="Name">{item.name}</td>
                      <td data-th="Hours Completed">{item.hours}</td>
                      <td data-th="Certificate No">{item.certificateNo}</td>
                      <td data-th="Course">{item.course}</td>
                      <td data-th="DOB">{item.dob}</td>
                      <td data-th="Award Date">{item.award}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
          {data.length === 0 && (
            <p style={{ textAlign: 'center' }}>Invalid certificate number</p>
          )}
        </>
      )}
    </div>
  );
}

export default CertificateDetailsTable;