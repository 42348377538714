
import { Link } from "react-router-dom";
import Rating from "../sidebar/rating";


const subTitle = "Loved by 200+ students";
const title = "Students Community Feedback";


// const studentList = [
//     {
//         imgUrl: 'assets/images/feedback/student/01.jpg',
//         imgAlt: 'student rajibraj91 rajibraj',
//         name: 'Oliver Beddows',
//         degi: 'UX designer',
//         desc: 'Rapidiously buildcollaboration anden deas sharing viaing and bleedng edgeing nterfaces fnergstcally plagiarize teams anbuilding paradgms whereas goingi forward process and monetze',
//     },
//     {
//         imgUrl: 'assets/images/feedback/student/02.jpg',
//         imgAlt: 'student rajibraj91 rajibraj',
//         name: 'Madley Pondor',
//         degi: 'UX designer',
//         desc: 'Rapidiously buildcollaboration anden deas sharing viaing and bleedng edgeing nterfaces fnergstcally plagiarize teams anbuilding paradgms whereas goingi forward process and monetze',
//     },
// ]

const studentList = [
    {
        imgUrl: 'assets/images/feedback/student/02.jpg',
        imgAlt: 'Rahul Kumar',
        name: 'Rahul Kumar',
        degi: 'Digital Marketing',
        desc: "I'm grateful for the Diploma in Digital Marketing course. It expanded my horizons and allowed me to develop innovative marketing strategies. The instructors are exceptional."
    },
    {
        imgUrl: 'assets/images/feedback/student/01.jpg',
        imgAlt: 'Indian student - Madley Pondor',
        name: 'Anjali Singh',
        degi: 'Graphic Design',
        desc: "Taking the Diploma Course in Graphic Design was a fantastic experience. It broadened my design skills, and I learned to create visually stunning graphics and layouts. Highly recommended!"
    }
];



const Student = () => {
    return (
        <div className="student-feedbak-section padding-tb shape-img">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="row justify-content-center row-cols-lg-2 row-cols-1">
                        <div className="col">
                            <div className="sf-left">
                                <div className="sfl-thumb">
                                    <img src="assets/images/feedback/01.jpg" alt="student feedback" />
                                    {/* <a href="https://www.youtube.com/embed/MU3qrgR2Kkc" className="video-button popup" target="_blank"><i className="icofont-ui-play"></i></a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            {studentList.map((val, i) => (
                                <div className="stu-feed-item" key={i}>
                                    <div className="stu-feed-inner">
                                        <div className="stu-feed-top">
                                            <div className="sft-left">
                                                <div className="sftl-thumb">
                                                    <img src={`${val.imgUrl}`} alt={val.imgAlt} />
                                                </div>
                                                <div className="sftl-content">
                                                    <Link to="/team-single"><h6>{val.name}</h6></Link>
                                                    <span>{val.degi}</span>
                                                </div>
                                            </div>
                                            <div className="sft-right">
                                                <Rating />
                                            </div>
                                        </div>
                                        <div className="stu-feed-bottom">
                                            <p>{val.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Student;