import { useEffect, useState } from "react";
import '../assets/css/loginCss.css';
import { Button } from "@nextui-org/react";
const AdminPage = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    // useEffect(() => {
    //     const jwt = localStorage.getItem("token");
    //     if (jwt) {
    //         window.location.href = "/admin/dashboard";
    //     }
    // })

    async function login(event) {
        event.preventDefault();
        setIsLoading(true);
        const response = await fetch('https://certificate-six.vercel.app/api/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password }),
        })

        const data = await response.json();

        if (data.user) {
            setIsLoading(false);
            localStorage.setItem("token", data.user);
            alert("Login Success");
            window.location.href = "/admin/dashboard";
        } else {
            setIsLoading(false);
            alert("Login Failed");
        }
    }
    return (
        <div>
            <h1>Admin Page</h1>
            <div className="login-page">
                <div className="form">
                    <form className="login-form">
                    {/* <Input
                        type="email"
                        required="true"
                        key="outside"
                        value={email}
                        variant="bordered"
                        label="Email"
                        labelPlacement="outside"
                        onChange={(e) => setEmail(e.target.value)}
                        // placeholder="Enter Admin Email"
                    />
                    <Input
                        type="password"
                        required="true"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter Admin Password"
                    /> */}
                        <input type="email" placeholder="email" onChange={(e) => setEmail(e.target.value)} />
                        <input type="password" placeholder="password" onChange={(e) => setPassword(e.target.value)} />
                        {/* <button type="submit">login</button> */}
                        <Button
                            isLoading={isLoading}
                            color="success"
                            onClick={login}
                            spinner={
                            <svg
                                className="animate-spin h-5 w-5 text-current"
                                fill="none"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                                />
                                <path
                                className="opacity-75"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                fill="currentColor"
                                />
                            </svg>
                            }
                        >
                            {isLoading ? 'Trying' : 'Login'}
                        </Button>
                    </form>
                </div>
            </div>
            {/* <form onSubmit={login}>
                <input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                <input type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                <input type="submit" value="Login"/>
            </form> */}
        </div>
    );
}

export default AdminPage;