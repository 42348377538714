


// const Pagination = () => {
//     return (
//         <ul className="default-pagination lab-ul">
//             <li>
//                 <a href="#"><i className="icofont-rounded-left"></i></a>
//             </li>
//             <li>
//                 <a href="#">01</a>
//             </li>
//             <li>
//                 <a href="#" className="active">02</a>
//             </li>
//             <li>
//                 <a href="#">03</a>
//             </li>
//             <li>
//                 <a href="#"><i className="icofont-rounded-right"></i></a>
//             </li>
//         </ul>
//     );
// }
 
// export default Pagination;


import React from "react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <ul className="default-pagination lab-ul">
      <li>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            if (currentPage > 1) {
              onPageChange(currentPage - 1);
            }
          }}
        >
          <i className="icofont-rounded-left"></i>
        </a>
      </li>
      {pageNumbers.map((number) => (
        <li key={number}>
          <a
            href="#"
            className={currentPage === number ? "active" : ""}
            onClick={(e) => {
              e.preventDefault();
              onPageChange(number);
            }}
          >
            {number}
          </a>
        </li>
      ))}
      <li>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            if (currentPage < totalPages) {
              onPageChange(currentPage + 1);
            }
          }}
        >
          <i className="icofont-rounded-right"></i>
        </a>
      </li>
    </ul>
  );
};

export default Pagination;
