
import { useEffect, useState } from "react";
import Rating from "../sidebar/rating";
const categoryList = [
    {
        text: 'Adobe XD',
        className: 'course-cate',
    },
    {
        text: '30% Off',
        className: 'course-offer',
    },
]

const PageHeaderTwo = ({cate}) => {
    const [selectedCourse, setSelectedCourse] = useState({});

    useEffect(() => {
        const courseData = localStorage.getItem('selectedCourse');
        try {
            if (courseData) {
            const parsedData = JSON.parse(courseData);
            setSelectedCourse(parsedData); 
            }
        } catch (error) {
            // Handle any parsing errors here
            console.log('Error parsing course data from localStorage:');
        }
      }, []);
    return (
        <div className="pageheader-section style-2">
            <div className="container">
                <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
                    <div className="col-lg-7 col-12">
                        <div className="pageheader-thumb">
                            <img src="assets/images/pageheader/02.jpg" alt="rajibraj91" className="w-100" />
                            {/* <a href={videoLink} className="video-button popup" target="_blank"><i className="icofont-ui-play"></i></a> */}
                        </div>
                    </div>
                    <div className="col-lg-5 col-12">
                        <div className="pageheader-content">
                            <div className="course-category">
                                <a href="" className="course-cate">{cate}</a>
                                {/* {cList.map((val, i) => (
                                    <a href={val.link} className={val.className} key={i}>{val.text}</a>
                                ))} */}
                            </div>
                            <h2 className="phs-title">{selectedCourse.title}</h2>
                            <p className="phs-desc">{selectedCourse.details}</p>
                            {/* <div className="phs-thumb">
                                <img src="assets/images/pageheader/03.jpg" alt="rajibraj91" />
                                <span>{author}</span>
                                <div className="course-reiew">
                                    <Rating />
                                    <span className="ratting-count">{reviewCount}</span>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default PageHeaderTwo;