import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import FooterTwo from "../component/layout/footer-2";
import HeaderThree from "../component/layout/header-3";
import Pagination from "../component/sidebar/pagination";
import Rating from "../component/sidebar/rating";
import { useNavigate } from "react-router-dom";

const courseList = [
  {
    imgUrl: 'assets/images/course/14.jpg',
    imgAlt: 'Basic Digital Marketing',
    description: `The Basic Digital Marketing course covers the fundamentals of digital marketing, website optimization, social media marketing, email marketing, and more. It's designed to provide a strong foundation in digital marketing principles.`,
    details: `By completing this course, you will gain knowledge in website design, SEO, social media marketing, email campaigns, content creation, and optimizing content for search engines. You'll also explore paid advertising methods to drive traffic and conversions.`,
    whatUlearn: [
      'Understanding the basics of digital marketing, its evolution, and its importance in today\'s business landscape.',
      'Learning how to create and optimize a website for marketing purposes, including website design principles, user experience, and SEO (Search Engine Optimization).',
      'Exploring popular social media platforms (e.g., Facebook, Twitter, Instagram) and how to use them for marketing, including content creation, advertising, and engagement strategies.',
      'Understanding email marketing techniques, including list building, segmentation, and creating effective email campaigns.',
      'Learning to create and distribute valuable and relevant content to attract and engage the target audience.',
      'Understanding how to optimize content and websites to improve their visibility in search engine results pages (SERPs).',
      'Pay-Per-Click Advertising (PPC), exploring paid advertising methods like Google Ads, Bing Ads, and social media advertising to drive traffic and conversions.',
    ],
    cate: 'Digital Marketing',
    title: 'Basic Digital Marketing',
    difficulty: 'Beginner',
    duration: '6 Months/1 Year',
    schedule: 'Offline Class',
    authorImgUrl: 'assets/images/course/author/05.jpg',
    btnText: 'Read More',
  },
  {
    imgUrl: 'assets/images/course/13.jpg',
    imgAlt: 'Advanced Digital Marketing',
    description: `The Advanced Digital Marketing course takes your digital marketing skills to the next level. It covers advanced SEO techniques, data analysis, advanced social media advertising, marketing automation, and more.`,
    details: `By completing this course, you will master advanced SEO strategies, data-driven decision-making, in-depth social media advertising, conversion optimization, marketing automation, and collaboration with influencers. You'll also gain knowledge of digital marketing regulations and industry-standard certifications.`,
    whatUlearn: [
      'Advanced SEO Techniques into SEO strategies, including keyword research, on-page and off-page optimization, and SEO for mobile and voice search.',
      'Advanced data analysis techniques to make data-driven decisions and optimize digital marketing campaigns.',
      'In-depth knowledge of paid advertising on social media platforms, including audience targeting, ad formats, and budget management.',
      'Strategies for marketing products and services online, including conversion optimization and online sales funnels.',
      'Using marketing automation tools to streamline marketing processes, nurture leads, and improve efficiency.',
      'Understanding how to collaborate with influencers and leverage their reach for marketing purposes.',
      'Understanding digital marketing regulations and ethical practices, including data privacy and online advertising regulations.',
      'Preparing for industry-standard certifications such as Google Ads and Google Analytics certifications, which can enhance career prospects.',
    ],
    cate: 'Digital Marketing',
    title: 'Advanced Digital Marketing',
    difficulty: 'Intermediate',
    duration: '1 Year',
    schedule: 'Offline Class',
    authorImgUrl: 'assets/images/course/author/05.jpg',
    btnText: 'Read More',
  },    
  {
    imgUrl: 'assets/images/course/01.jpg',
    imgAlt: 'Diploma in Computer Application',
    description: `A basic course in computer, has been designed for beginners, provides funda-mental knowledge and skills needed to use a computer effectively. These courses aim to make individuals comfortable with basic computer operations and soft-ware usage.`,
    details: 'A Basic Course in Computer has been designed to provide individuals with the foundational skills needed to operate a computer for personal and basic profes-sional tasks. These courses are especially valuable for those who are new to using computers or need to refresh their computer skills. They serve as a starting point for further learning and more advanced computer courses.',
    whatUlearn: [
      'Understanding the basic components of a computer, including the central processing unit (CPU), monitor, keyboard, mouse, and storage devices.',
      'Introduction to common operating systems like Windows, macOS, or Linux, including navigating the user interface.',
      'Learning how to create, organize, and manage files and folders on the computer.',
      'Familiarity with the desktop environment, icons, and shortcuts for launch-ing applications and files.',
      'Basic word processing skills, including typing, formatting text, and saving documents.',
      'Introduction to web browsing, search engines, and online safety practices.',
      'Setting up and using email accounts, composing and sending emails, and managing email messages.',
      'Understanding how to view and play multimedia content like images, vid-eos, and audio files.',
      'Basic knowledge of computer security best practices, including password management and antivirus software.',
      'Introduction to common software applications, such as web browsers, of-fice suites (e.g., Microsoft Office or Google Workspace), and media players.',
      'Learning to print documents and images from the computer.',
      'Familiarity with essential keyboard shortcuts for tasks like copy, paste, cut, and undo.',
    ],
    cate: 'Computer Application',
    title: 'Basic Computer Course',
    difficulty: 'Beginner',
    duration: '12 Weeks',
    schedule: 'Offline Class',
    authorImgUrl: 'assets/images/course/author/01.jpg',
    btnText: 'Read More',
  },
  {
    imgUrl: 'assets/images/course/02.jpg',
    imgAlt: 'Diploma in Computer Application',
    description: `A Diploma in Computer Application (DCA) is a comprehensive course that provides students with a wide range of knowledge and practical skills related to computer applications and software.`,
    details: `A Diploma in Computer Application aims to provide students with a strong foundation in computer usage, software applications, and basic programming concepts. It prepares individuals for a variety of roles that require computer skills, such as office administration, data entry, customer support, and entry-level IT positions. It also serves as a valuable qualification for those looking to enhance their computer literacy and improve their employability in various industries.`,
    whatUlearn: [
      'Understanding the basic components of a computer, hardware and software, and their functions.',
      'Proficiency in using common operating systems like Windows, macOS, or Linux, including file management and system customization.',
      'Learning to use word processing software (e.g., Microsoft Word) to create, format, and edit documents.',
      'Developing skills in spreadsheet software (e.g., Microsoft Excel) for data entry, analysis, and visualization.',
      'Creating and delivering presentations using software like Microsoft PowerPoint.',
      'Understanding the concepts of databases and using database software (e.g., Microsoft Access) for data storage and retrieval.',
      'Setting up and managing email accounts, sending and receiving emails, and using email clients.',
      'Effective web browsing, online research, and using web-based applications.',
      'Introduction to programming concepts and simple programming languages like Python.',
      'Using graphics and multimedia software for editing images and creating multimedia presentations.',
      'Understanding computer networks, internet connectivity, and basic network troubleshooting.',
      'Introduction to web development concepts, HTML, and web design principles.',
      'Installing, updating, and uninstalling software applications.',
      'Learning to back up and restore data to prevent data loss.',
      'Basic knowledge of computer security practices, including password management and antivirus software.',
      'Compressing and decompressing files and folders using compression tools.',
      'Using remote desktop tools to access and control computers from a distance.',
    ],
    cate: 'Computer Application',
    title: 'Diploma in Computer Application (DCA)',
    difficulty: 'Advanced',
    duration: '48 Weeks',
    schedule: 'Offline Class',
    authorImgUrl: 'assets/images/course/author/02.jpg',
    btnText: 'Read More',
  },
  {
    imgUrl: 'assets/images/course/03.jpg',
    imgAlt: 'Diploma in Business & Financial Software Application',
    description: `A Diploma in Business & Financial Software Application is designed to equip students with the skills and knowledge needed to effectively use software applications specifically tailored for business and financial management. This diploma program aims to prepare individuals for roles that require proficiency in financial software tools, accounting software, and business-related applications.`,
    details: `Upon completing a Diploma in Business & Financial Software Application, the learner is well-prepared for roles that require proficiency in financial software applications and accounting principles. These roles may include financial analysts, accounting clerks, bookkeepers, tax professionals, business analysts, and other positions that involve financial management and analysis. The program provides valuable skills for managing financial data, conducting financial analysis, and supporting business decision-making in various industries and sectors.`,
    whatUlearn: [
      'Proficiency in using financial accounting software like QuickBooks, Xero, or other similar platforms for tasks such as bookkeeping, financial statement preparation, and accounts management.',
      'Advanced skills in data analysis and modeling using spreadsheet software (e.g., Microsoft Excel) for financial forecasting, budgeting, and financial analysis.',
      'Understanding fundamental accounting concepts and principles, including double-entry bookkeeping and accrual accounting.',
      'Creating and interpreting financial reports, including income statements, balance sheets, and cash flow statements.',
      'Introduction to taxation software for calculating and managing taxes, deductions, and compliance with tax regulations.',
      'Learning to process payroll using software tools, including calculating wages, deductions, and payroll tax obligations.',
      'Portfolio management using financial software.',
      'Familiarity with Enterprise Resource Planning (ERP) systems and their role in integrated business and financial management.',
    ],
    cate: 'Business & Finance',
    title: 'Diploma in Business & Financial Software Application',
    difficulty: 'Advanced',
    duration: '48 Weeks',
    schedule: 'Offline Class',
    authorImgUrl: 'assets/images/course/author/03.jpg',
    btnText: 'Read More',
  },
  {
    imgUrl: 'assets/images/course/04.jpg',
    imgAlt: 'Diploma in Financial Accounting',
    description: `The Diploma in Financial Accounting course provides a comprehensive understanding of financial accounting principles and practices. Students will learn how to prepare and analyze financial statements, including the income statement, balance sheet, and cash flow statement.`,
    details: `By the end of this course, you will learn the fundamental accounting principles and concepts that underpin financial accounting, including the accrual basis of accounting, the matching principle, and the revenue recognition principle. You will also gain hands-on experience in recording financial transactions accurately and in accordance with generally accepted accounting principles (GAAP).`,
    whatUlearn: [
      'How to prepare and analyze financial statements, including the income statement, balance sheet, and cash flow statement.',
      'How to record financial transactions accurately and in accordance with generally accepted accounting principles (GAAP).',
      'Learn about the double-entry accounting system, which is the foundation of modern accounting. This involves debits and credits, and how they affect accounts.',
      'Learn to use accounting software packages like QuickBooks or Excel for accounting purposes.',
    ],
    cate: 'Finance & Accounting',
    title: 'Diploma in Financial Accounting',
    difficulty: 'Advanced',
    duration: '48 Weeks',
    schedule: 'Offline Class',
    authorImgUrl: 'assets/images/course/author/04.jpg',
    btnText: 'Read More',
  },
  {
    imgUrl: 'assets/images/course/06.jpg',
    imgAlt: 'Diploma Course in Tally ERP',
    description: `The Diploma Course in Tally ERP provides comprehensive knowledge of Tally ERP software and its importance in business operations. It covers installation, configuration, data entry, chart of accounts, vouchers, inventory management, GST accounting, financial reporting, payroll, data import/export, and more.`,
    details: `Upon completing this course, you will be proficient in using Tally ERP for various accounting and financial tasks. You'll learn to manage financial data accurately, reconcile bank statements, handle inventory, and ensure compliance with tax laws and regulations. Additionally, you'll explore advanced features like job costing, budgeting, and scenario management.`,
    whatUlearn: [
      'Understanding the basics of Tally ERP, its features, and its significance in business operations.',
      'Installing the Tally ERP software, configuring settings, and setting up user accounts and permissions.',
      'Learning how to enter financial data, transactions, and business information into Tally ERP accurately and efficiently.',
      'Setting up and managing the chart of accounts, which includes various ledgers, groups, and subgroups to categorize financial data.',
      'Entering various types of vouchers such as sales vouchers, purchase vouchers, payment vouchers, and receipt vouchers.',
      'Managing and tracking inventory, including stock items, units of measurement, and stock categories.',
      'Reconciling bank statements with Tally ERP records to ensure accuracy in financial transactions.',
      'Understanding and implementing GST accounting procedures, including GST registration, tax calculation, and filing GST returns.',
      'Generating financial reports like balance sheets, income statements, and cash flow statements using Tally ERP.',
      'Managing employee payroll, including salary calculations, deductions, and generating payslips.',
      'Handling data import and export operations, such as transferring data to and from Excel or other formats.',
      'Dealing with transactions in multiple currencies for businesses involved in international trade.',
      'Ensuring compliance with relevant tax laws and regulations, including preparation for tax audits.',
      'Exploring advanced features of Tally ERP, such as job costing, budgeting, and scenario management.',
    ],
    cate: 'Enterprise Software',
    title: 'Diploma Course in Tally ERP',
    difficulty: 'Advanced',
    duration: '48Weeks',
    schedule: 'Online Class',
    authorImgUrl: 'assets/images/course/author/06.jpg',
    btnText: 'Read More',
  },
  {
    imgUrl: 'assets/images/course/07.jpg',
    imgAlt: 'Sage 50 Accounts and Payroll',
    description: `The Sage 50 Accounts and Payroll course provides comprehensive knowledge of Sage 50 software and its importance in business accounting and payroll management. It covers company profile setup, financial transactions, VAT accounting, inventory management, budgeting, fixed assets, payroll processing, and more.`,
    details: `Upon completing this course, you will be proficient in using Sage 50 for various accounting and payroll functions. You'll learn to configure company profiles, handle financial transactions, reconcile bank statements, manage VAT, maintain customer and supplier records, generate financial reports, process payroll, and comply with tax regulations.`,
    whatUlearn: [
      'Understanding the software\'s interface, features, and its importance in business accounting.',
      'Creating and configuring a company profile in Sage 50, including setting up chart of accounts, fiscal years, and tax codes.',
      'Learning how to accurately enter financial transactions, sales invoices, purchase orders, and general ledger entries into Sage 50.',
      'Reconciling bank statements with Sage 50 records to ensure accuracy in financial transactions.',
      'Handling VAT accounting procedures, including VAT registration, tax calculation, and generating VAT returns.',
      'Creating and maintaining customer and supplier records, tracking accounts receivable and accounts payable.',
      'Generating financial reports like balance sheets, income statements, and cash flow statements using Sage 50.',
      'Managing and tracking inventory, including stock items, pricing, and stock valuation.',
      'Creating budgets, comparing actual performance against budgeted figures, and analyzing variances.',
      'Managing and depreciating fixed assets within Sage 50.',
      'Handling transactions in multiple currencies for businesses involved in international trade.',
      'Implementing data backup and security measures to protect financial data and ensure its availability.',
      'Customizing reports and documents, such as invoices and purchase orders, to match business requirements.',
      'Closing financial years, producing year-end reports, and preparing for audits.',
      'Configuring payroll settings, including employee records, tax codes, and payroll elements.',
      'Learning how to process payroll, calculate salaries, deductions, and benefits, and generate payslips.',
      'Understanding and handling statutory deductions like income tax, National Insurance contributions, and pension contributions.',
      'Complying with HMRC\'s Real-Time Information requirements for payroll reporting.',
      'Generating payroll reports and documents, including P60s, P11Ds, and payroll journals.',
      'Completing payroll year-end procedures, including generating and submitting annual reports to tax authorities.',
      'Staying up to date with employment laws and regulations related to payroll processing.',
      'Identifying and resolving common issues and errors in Sage 50 Accounts and Payroll.',
    ],
    cate: 'Accounting & Finance',
    title: 'Sage 50 Accounts and Payroll',
    difficulty: 'Advanced',
    duration: '48 Weeks',
    schedule: 'Online Class',
    authorImgUrl: 'assets/images/course/author/07.jpg',
    btnText: 'Read More',
  },
  {
    imgUrl: 'assets/images/course/08.jpg',
    imgAlt: 'Course in Digital Banking',
    description: `The Course in Digital Banking provides a comprehensive understanding of digital banking's evolution and significance in the modern financial landscape. It covers various aspects, including online banking platforms, digital payment methods, fintech innovations, cybersecurity, blockchain, and customer experience enhancements.`,
    details: `By completing this course, you will gain expertise in digital banking tools, technologies, and strategies. You'll learn about online banking, mobile banking, digital payment methods, fintech's role, cybersecurity, blockchain, customer experience, digital lending, wealth management, and marketing strategies. This knowledge is highly valuable for roles in digital banking, fintech, and the broader financial sector.`,
    whatUlearn: [
      'Understanding the evolution and significance of digital banking in the modern financial landscape.',
      'Exploring how technology has transformed traditional banking processes and services.',
      'Learning to navigate and use online banking platforms and mobile banking apps effectively.',
      'Understanding various digital payment methods, including electronic funds transfers, mobile payments, and digital wallets.',
      'Exploring the role of fintech (financial technology) in shaping digital banking services and innovations.',
      'Understanding the importance of cybersecurity in digital banking, including data protection and fraud prevention measures.',
      'An overview of blockchain technology and cryptocurrencies like Bitcoin and their impact on banking.',
      'Examining how digital banking improves the customer experience, including 24/7 access to accounts and self-service options.',
      'Understanding the digital lending process, including online loan applications and credit assessment using digital data.',
      'Learning about digital tools and platforms used for wealth management, investment, and financial planning.',
      'Ensuring the security of mobile banking apps and devices.',
      'Strategies for marketing digital banking services and attracting and retaining customers.',
    ],
    cate: 'Finance & Banking',
    title: 'Course in Digital Banking',
    difficulty: 'Beginner',
    duration: '4 Weeks',
    schedule: 'Offline Class',
    authorImgUrl: 'assets/images/course/author/08.jpg',
    btnText: 'Read More',
  },
  {
    imgUrl: 'assets/images/course/09.jpg',
    imgAlt: 'Course in Data Entry Operator',
    description: `The Course in Data Entry Operator focuses on developing essential skills for data entry professionals. Students will learn typing skills, computer hardware and software basics, and various data entry techniques. Proficiency in data entry software, accuracy in numeric and text data entry, and data privacy awareness are key components of this course.`,
    details: `Upon completion of this course, you will be well-prepared to work as a data entry professional in various industries. You will have gained proficiency in data entry software, data accuracy, data privacy, spreadsheet software like Microsoft Excel, database entry, and efficient digital file management.`,
    whatUlearn: [
      'Typing skills and learning about computer hardware, software, and operating systems.',
      'Learning different data entry techniques, including manual data entry and data import/export.',
      'Gaining proficiency in data entry software and tools commonly used in the industry, such as Microsoft Excel, data entry software suites, and database management systems.',
      'Entering numeric data accurately, which is often required in accounting, finance, and statistical roles.',
      'Entering both numbers and text accurately, which is used in various administrative and clerical tasks.',
      'Checking and verifying data for accuracy and completeness.',
      'Cleaning and formatting data to ensure consistency and usability.',
      'Converting data from one format to another, such as converting paper documents into digital formats.',
      'Understanding the importance of data privacy and security measures, especially when handling sensitive information.',
      'Proficiency in using spreadsheet software like Microsoft Excel for data entry, organization, and basic data analysis.',
      'Learning to input data into database systems and maintaining database records.',
      'Managing digital files and documents efficiently, including naming conventions and folder structures.',
      'Becoming familiar with keyboard shortcuts to increase efficiency in data entry tasks.',
    ],
    cate: 'Data Entry',
    title: 'Course in Data Entry Operator',
    difficulty: 'Intermediate',
    duration: '24 Weeks',
    schedule: 'Offline Class',
    authorImgUrl: 'assets/images/course/author/09.jpg',
    btnText: 'Read More',
  },  
  {
    imgUrl: 'assets/images/course/10.jpg',
    imgAlt: 'Course in Advanced Financial Accounting with Tally',
    description: `The Course in Advanced Financial Accounting with Tally focuses on exploring advanced features and functionalities of Tally ERP, including advanced inventory management, multiple cost centers, and advanced taxation modules. Students will learn to create customized financial reports, manage transactions in multiple currencies, and understand complex tax calculations.`,
    details: `Upon completion of this course, you will have an in-depth understanding of financial accounting principles, advanced Tally ERP functionalities, and the ability to handle complex accounting tasks and financial reporting for businesses. This knowledge and expertise can be valuable for careers in accounting, finance, auditing, and financial management in various industries.`,
    whatUlearn: [
      'Exploring advanced features and functionalities of Tally ERP, including advanced inventory management, multiple cost centers, and advanced taxation modules.',
      'Learning to prepare consolidated financial statements for group companies or subsidiaries using Tally ERP.',
      'Creating customized financial reports, dashboards, and statements using Tally ERP\'s reporting tools.',
      'Understanding advanced costing methods and budgeting techniques within Tally ERP.',
      'Managing transactions in multiple currencies and dealing with foreign exchange rate fluctuations.',
      'In-depth knowledge of taxation concepts, including complex tax calculations, GST reconciliations, and tax planning using Tally ERP.',
      'Understanding how to account for business combinations, mergers, and acquisitions in financial statements.',
      'Managing complex inventory scenarios, including serialized inventory, batch-wise inventory, and multi-location inventory management.',
      'Learning to create customized scripts and workflows in Tally ERP to automate specific accounting processes.',
      'Applying job costing techniques in Tally ERP for businesses involved in projects or contract-based work.',
      'Utilizing Tally ERP to manage financial transactions with banks, including loans, overdrafts, and reconciliation.',
      'Advanced financial analysis using Tally ERP\'s tools and functions, including ratio analysis, trend analysis, and forecasting.',
      'Understanding auditing principles, internal control procedures, and the role of Tally ERP in facilitating auditing processes.',
      'Implementing robust data security measures and backup strategies to protect financial data.',
      'Learning to migrate data from other accounting systems into Tally ERP and integrate Tally with other software systems.',
    ],
    cate: 'Financial Accounting',
    title: 'Course in Advanced Financial Accounting with Tally',
    difficulty: 'Intermediate',
    duration: '24 Weeks',
    schedule: 'Offline Class',
    authorImgUrl: 'assets/images/course/author/10.jpg',
    btnText: 'Read More',
  },
  {
    imgUrl: 'assets/images/course/11.jpg',
    imgAlt: 'Diploma Course in Office Automation',
    description: `The Diploma Course in Office Automation focuses on providing students with proficiency in using Microsoft Office applications such as Word, Excel, PowerPoint, and Outlook. Students will learn to create and format documents, reports, presentations, and effectively manage emails and digital files. Additionally, this course covers the basics of database management systems, data entry, and querying using software like Microsoft Access.`,
    details: `Upon completing this diploma course, students will have the skills and knowledge to effectively use a variety of digital tools and technologies to streamline office processes, enhance productivity, and improve communication and collaboration within a professional environment. These skills are valuable in a wide range of office and administrative roles across different industries.`,
    whatUlearn: [
      'Understanding the concept of office automation and its significance in modern workplaces.',
      'Proficiency in using Microsoft Office applications such as Word, Excel, PowerPoint, and Outlook for word processing, spreadsheet analysis, presentation creation, and email management.',
      'Learning to create and format documents, reports, and presentations with a focus on professionalism and consistency.',
      'Developing skills in creating and manipulating spreadsheets for data entry, analysis, and visualization using Excel.',
      'Understanding the basics of database management systems, data entry, and querying using software like Microsoft Access.',
      'Effective management of emails, including organizing, archiving, and using email clients like Microsoft Outlook.',
      'Using digital calendars and scheduling tools to manage appointments, meetings, and tasks.',
      'Organizing and managing digital files and documents efficiently, including version control and folder structures.',
      'Learning to enter and process data accurately and efficiently, which is essential for various office tasks.',
      'Developing compelling and visually appealing presentations using PowerPoint or similar software.',
      'Exploring collaboration and communication tools like Microsoft Teams, Slack, or similar platforms to enhance teamwork and communication.',
      'Using cloud-based office suites like Google Workspace (formerly G Suite) or Microsoft 365 for collaborative document editing and sharing.',
      'Familiarity with document scanning, optical character recognition (OCR), and converting scanned documents into editable formats.',
      'Utilizing digital note-taking apps and tools to capture and organize information.',
      'Exploring automation tools and techniques to streamline repetitive tasks and improve efficiency.',
    ],
    cate: 'Office Automation',
    title: 'Diploma Course in Office Automation',
    difficulty: 'Intermediate',
    duration: '24 Weeks',
    schedule: 'Offline Class',
    authorImgUrl: 'assets/images/course/author/11.jpg',
    btnText: 'Read More',
  },
  {
    imgUrl: 'assets/images/course/12.jpg',
    imgAlt: 'Diploma Course in Web Designing',
    description: `The Diploma Course in Web Designing covers the fundamentals of web development, including HTML and CSS. Students will learn to create responsive websites that adapt to various devices and screen sizes. Additionally, this course explores design principles, graphic design skills, user experience (UX) design, and web hosting.`,
    details: `Upon completing this diploma course, students will have the skills and knowledge to design and create visually appealing, user-friendly, and functional websites. This skill set is valuable in a wide range of industries, and web designers are in demand for various roles, including web designer, front-end developer, UI/UX designer, and digital marketing specialist, among others.`,
    whatUlearn: [
      'Learning the fundamentals of HTML, the standard markup language used for creating web pages.',
      'Understanding how to use CSS to control the layout, formatting, and styling of web pages.',
      'Learning to design websites that adapt and display correctly on various devices and screen sizes, including desktops, tablets, and smartphones.',
      'Exploring design fundamentals, including color theory, typography, layout, and visual hierarchy.',
      'Developing graphic design skills to create logos, icons, and images for web use using tools like Adobe Photoshop or Illustrator.',
      'Understanding UX principles and creating websites that provide a positive user experience, including user interface (UI) design.',
      'Learning to create wireframes and prototypes to plan and visualize website layouts and functionality.',
      'Designing webpage layouts using grids, columns, and responsive design techniques.',
      'Choosing and styling fonts for web content to enhance readability and aesthetics.',
      'Optimizing and integrating images, videos, and other multimedia elements into web pages.',
      'Creating intuitive and user-friendly website navigation systems.',
      'Implementing interactive elements such as buttons, forms, and sliders using HTML, CSS, and JavaScript.',
      'Ensuring that websites are accessible to individuals with disabilities, following WCAG (Web Content Accessibility Guidelines) standards.',
      'Understanding web hosting services, domain registration, and website deployment.',
      'Familiarity with popular CMS platforms like WordPress and Joomla for website creation and management.',
      'Learning basic SEO techniques to improve website visibility in search engine results.',
      'Using web analytics tools like Google Analytics to track and analyze website traffic and user behavior.',
    ],
    cate: 'Web Designing',
    title: 'Diploma Course in Web Designing',
    difficulty: 'Intermediate',
    duration: '24 Weeks',
    schedule: 'Offline Class',
    authorImgUrl: 'assets/images/course/author/12.jpg',
    btnText: 'Read More',
  },
  {
    imgUrl: 'assets/images/course/13.jpg',
    imgAlt: 'Diploma Course in Graphic Design',
    description: `The Diploma Course in Graphic Design covers the fundamental principles of design, typography, color theory, and digital tools used in graphic design. Students will learn to create visually appealing layouts, illustrations, and digital graphics for various media.`,
    details: `Upon completing this diploma course, students will have the skills and knowledge to work as a graphic designer in a wide range of industries, including advertising, marketing, publishing, and web development. Graphic designers are responsible for creating visual content that communicates messages effectively and engages audiences, making this skill set highly valuable in today's visual-centric world.`,
    whatUlearn: [
      'Understanding the fundamental principles of design, including balance, contrast, alignment, and proximity.',
      'Learning about fonts, typefaces, and how to effectively use typography in design projects.',
      'Exploring the psychology of color, color harmonies, and how to choose and apply colors in design.',
      'Creating visually appealing layouts and compositions for various design projects.',
      'Developing illustration skills using digital tools and software such as Adobe Illustrator.',
      'Proficiency in image editing software like Adobe Photoshop for retouching and manipulating images.',
      'Understanding vector graphics and creating vector illustrations and designs.',
      'Designing layouts for print materials such as brochures, magazines, and posters using software like Adobe InDesign.',
      'Creating logos and visual identities for businesses and organizations.',
      'Understanding and developing brand identities, including logos, color palettes, and style guides.',
      'Preparing design files for print, including knowledge of print processes and file formats.',
      'Designing graphics and visuals for digital media, including websites, social media, and apps.',
      'Creating user-friendly and visually appealing interfaces for websites and applications.',
      'Introduction to motion graphics and creating animated visuals for video and web.',
      'Designing product packaging and labels for various consumer goods.',
      'Creating eye-catching and effective advertising materials, including banners and posters.',
      'Designing graphics and signage for physical spaces, such as retail stores and exhibitions.',
      'Building a portfolio of design projects to showcase your skills to potential employers or clients.',
    ],
    cate: 'Graphic Designing',
    title: 'Diploma Course in Graphic Design',
    difficulty: 'Intermediate',
    duration: '24 Weeks',
    schedule: 'Offline Class',
    authorImgUrl: 'assets/images/course/author/13.jpg',
    btnText: 'Read More',
  },
  {
    imgUrl: 'assets/images/course/14.jpg',
    imgAlt: 'Certificate Course in Network Administration',
    description: `A certificate course in Network Administration provides learners with the fundamental knowledge and skills needed to manage and maintain computer networks effectively. Network administrators play a critical role in ensuring the smooth operation and security of an organization's computer systems and network infrastructure.`,
    details: `Upon completing this certificate course, learners will have the skills and knowledge necessary to work as network administrators or in similar roles in IT departments, data centers, or network service providers.`,
    whatUlearn: [
      'Understanding the basics of computer networks, including network types, topologies, and components.',
      'Learning about common networking protocols like TCP/IP, HTTP, DNS, and DHCP.',
      'Familiarity with networking hardware, including routers, switches, hubs, and network cables.',
      'Configuring and managing network devices, including routers, switches, and firewalls.',
      'Understanding IP addressing, subnetting, and IP routing principles.',
      'Implementing security measures to protect the network from unauthorized access, threats, and attacks.',
      'Using network monitoring tools to track network performance and troubleshoot issues.',
      'Setting up and securing wireless networks, including Wi-Fi access points and wireless security protocols.',
      'Configuring remote access solutions, such as VPNs (Virtual Private Networks) and remote desktop services.',
      'Developing troubleshooting skills to identify and resolve network-related problems.',
      'Proficiency in using network administration tools and software for tasks like device configuration and monitoring.',
      'Setting up and managing Domain Name System (DNS) servers and domain names.',
      'Configuring Dynamic Host Configuration Protocol (DHCP) servers for automatic IP address assignment.',
      'Managing firewall rules and policies to control network traffic.',
      'Implementing data backup and recovery strategies for network resources.',
      'Understanding virtualization technologies and their role in network administration.',
    ],
    cate: 'Network Administration',
    title: 'Certificate Course in Network Administration',
    difficulty: 'Beginner',
    duration: '8 Weeks',
    schedule: 'Offline Class',
    authorImgUrl: 'assets/images/course/author/14.jpg',
    btnText: 'Read More',
  },
];


const coursesPerPage = 6; // Number of courses to display per page

const CoursePage = () => {
  const [selectedSortOption, setSelectedSortOption] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedCourses, setSortedCourses] = useState([]);
  const courseContainerRef = useRef(null);

  const navigate = useNavigate();

  const handleCourseClick = (courseData) => {
    // Store the course data in local storage
    localStorage.setItem("selectedCourse", JSON.stringify(courseData));

    // Navigate to the /course-single route
    navigate("/course-single");
 };

  const sortCourses = (option) => {
    let sortedCourses = [...courseList];
    if (option === "all") {
      // No sorting needed
    } else if (option === "price-low-to-high") {
      sortedCourses = sortedCourses.sort((a, b) => a.price - b.price);
    } else if (option === "price-high-to-low") {
      sortedCourses = sortedCourses.sort((a, b) => b.price - a.price);
    } else if (option === "rating-high-to-low") {
      // Implement sorting by rating if needed
    }

    return sortedCourses;
  };

  useEffect(() => {
    const initialSortedCourses = sortCourses(selectedSortOption);
    setSortedCourses(initialSortedCourses);
  }, [selectedSortOption]);

  const indexOfLastCourse = currentPage * coursesPerPage;
  const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
  const currentCourses = sortedCourses.slice(
    indexOfFirstCourse,
    indexOfLastCourse
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    // Scroll to the top of the course container when the page changes
    if (courseContainerRef.current) {
      window.scrollTo({
        top: courseContainerRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <HeaderThree />
      <div className="course-section padding-tb section-bg">
        <div className="container">
          <div className="section-wrapper">
            <div className="course-showing-part">
              <div className="d-flex flex-wrap align-items-center justify-content-between">
                <div className="course-showing-part-left">
                <p>
                    Showing {indexOfFirstCourse + 1}-{Math.min(indexOfLastCourse, courseList.length)} of{" "}
                    {courseList.length} results
                </p>

                </div>
                {/* <div className="course-showing-part-right d-flex flex-wrap align-items-center">
                  <span>Sort by :</span>
                  <div className="select-item">
                    <select
                      value={selectedSortOption}
                      onChange={(e) => setSelectedSortOption(e.target.value)}
                    >
                      <option value="all">All</option>
                      <option value="price-low-to-high">
                        Price: Low to High
                      </option>
                      <option value="price-high-to-low">
                        Price: High to Low
                      </option>
                    </select>
                    <div className="select-icon">
                      <i className="icofont-rounded-down"></i>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="row g-4 justify-content-center row-cols-xl-3 row-cols-md-2 row-cols-1" ref={courseContainerRef}>
              {currentCourses.map((val, i) => (
                <div className="col" key={i}>
                  <div className="course-item">
                    <div className="course-inner">
                      <div className="course-thumb">
                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                      </div>
                      <div className="course-content">
                        {/* <div className="course-price">{val.price}</div> */}
                        <div className="course-category">
                          <div className="course-cate">
                            <a href="#">{val.cate}</a>
                          </div>
                          <div className="course-reiew">
                            <Rating />
                            <span className="ratting-count">
                              {" "}
                              {val.reviewCount}
                            </span>
                          </div>
                        </div>
                        <Link to="/course-single">
                          <h4 onClick={() => handleCourseClick(val)}>{val.title}</h4>
                        </Link>
                        <div className="course-details">
                          {/* <div className="couse-count">
                            <i className="icofont-video-alt"></i>{" "}
                            {val.totalLeson}
                          </div> */}
                          <div className="couse-topic">
                            <i className="icofont-signal"></i> {val.schedule}
                          </div>
                          <div className="course-btn">
                            <Link to="/course-single">
                            <p className="lab-btn-text" onClick={() => handleCourseClick(val)}>
                              {val.btnText}{" "}
                              <i className="icofont-external-link"></i>
                            </p>
                            </Link>
                            {/* <Link to="/course-single" className="lab-btn-text">
                              {val.btnText}{" "}
                              <i className="icofont-external-link"></i>
                            </Link> */}
                          </div>
                        </div>
                        <div className="course-footer">
                          {/* <div className="course-author">
                            <img
                              src={`${val.authorImgUrl}`}
                              alt={`${val.authorImgAlt}`}
                            />
                            <Link to="/team-single" className="ca-name">
                              {val.authorName}
                            </Link>
                          </div> */}
                          {/* <div className="course-btn">
                            <Link to="/course-single">
                            <p className="lab-btn-text" onClick={() => handleCourseClick(val)}>
                              {val.btnText}{" "}
                              <i className="icofont-external-link"></i>
                            </p>
                            </Link>
                            <Link to="/course-single" className="lab-btn-text">
                              {val.btnText}{" "}
                              <i className="icofont-external-link"></i>
                            </Link>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(sortedCourses.length / coursesPerPage)}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
      <FooterTwo />
    </>
  );
};

export default CoursePage;
