import React, { useState } from "react";
import axios from "axios";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Input,
  Select,
  SelectItem,
} from "@nextui-org/react";

export default function App() {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [name, setName] = useState("");
  const [hours, setHours] = useState("");
  const [dob, setDob] = useState("");
  const [dobTemp, setDobTemp] = useState("");
  const [awardDate, setAwardDate] = useState("");
  const [awardDateTemp, setAwardDateTemp] = useState("");
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [course, setCourse] = useState("");

  const courses = [
    { value: 'Diploma in Computer Application', label: 'Diploma in Computer Application' },
    { value: 'Diploma in Office Automation', label: 'Diploma in Office Automation' },
    { value: 'Diploma in Computer Assembly and Maintenance', label: 'Diploma in Computer Assembly and Maintenance' },
    { value: 'Diploma in Financial Accounting', label: 'Diploma in Financial Accounting' },
    { value: 'Diploma in Desktop Publishing', label: 'Diploma in Desktop Publishing' },
    { value: 'Diploma in Graphic Designing', label: 'Diploma in Graphic Designing' },
    { value: 'Diploma in Web Designing', label: 'Diploma in Web Designing' },
    { value: 'Diploma in Business Software Application', label: 'Diploma in Business Software Application' },
    { value: 'Diploma in Digital Marketing', label: 'Diploma in Digital Marketing' },
    { value: 'Certificate Course in Web Designing', label: 'Certificate Course in Web Designing' },
    { value: 'Certificate Course in PC Assembly and Maintenance', label: 'Certificate Course in PC Assembly and Maintenance' },
    { value: 'Certificate Course in Network Administrator', label: 'Certificate Course in Network Administrator' },
    { value: 'Certificate Course in Programming through C Language', label: 'Certificate Course in Programming through C Language' },
    { value: 'Certificate Course in Programming in C ++', label: 'Certificate Course in Programming in C ++' },
    { value: 'Advance Diploma in Hardware Management', label: 'Advance Diploma in Hardware Management' },
    { value: 'Certificate Course in E-Commerce Design', label: 'Certificate Course in E-Commerce Design' },
    { value: 'Certificate Course in Graphic Designing', label: 'Certificate Course in Graphic Designing' },
    { value: 'MS Office Certification Program', label: 'MS Office Certification Program' },
    { value: 'Tally ERP Course', label: 'Tally ERP Course' },
    { value: 'Sage 50 Accounts and Payroll Diploma', label: 'Sage 50 Accounts and Payroll Diploma' },
    { value: 'Certificate Course in E-Commerce Design', label: 'Certificate Course in E-Commerce Design' },
    { value: 'Digital Banking', label: 'Digital Banking' },
    { value: 'Data Entry Operator Course', label: 'Data Entry Operator Course' }
  ];
  

  const generateCertificateNumber = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let certificateNumber = "";
    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      certificateNumber += characters.charAt(randomIndex);
    }
    return certificateNumber;
  };

  const handleAddStudent = () => {
    // Check if any field is empty
    if (!name || !hours || !dob || !awardDate) {
      setIsFormInvalid(true);
      return;
    }
    setIsLoading(true);
    const certificateNo = generateCertificateNumber();
    const courseArray = Array.from(course);
    const retrievedValue = courseArray[0];

    const studentData = {
      name: name,
      hours: hours,
      dob: dob,
      course: retrievedValue,
      award: awardDate,
      certificateNo: certificateNo, // Add the generated certificate number
    };
    // Send the studentData object to your server for adding a new student
    axios
      .post("https://certificate-six.vercel.app/students", studentData)
      .then((response) => {
        console.log("Student added successfully:", response.data);
        setIsLoading(false);
        onClose();
        // Optionally, you can fetch the updated student list here
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error adding student: ", error);

        // Check if the error message indicates a duplicate certificate number
        if (
          error.response &&
          error.response.data &&
          error.response.data.message === "Certificate number already exists."
        ) {
          setErrorMessage("Certificate number already exists. Please try again.");
          setIsErrorPopupOpen(true);
        }
      });
  };

  const handleBirthDateChange = (event) => {
    setDobTemp(event.target.value);
    const rawDateValue = event.target.value; 
    
    const [year, month, day] = rawDateValue.split('-');
    const formattedDate = `${day}/${month}/${year}`;

    setDob(formattedDate); 
  };

  const handleAwardDateChange = (event) => {
    setAwardDateTemp(event.target.value);
    const rawDateValue = event.target.value; 
    
    const [year, month, day] = rawDateValue.split('-');
    const formattedAwardDate = `${day}/${month}/${year}`;

    setAwardDate(formattedAwardDate);
  };

  const onClose = () => {
    // Clear form and reset validation
    window.location.reload();
    setName("");
    setHours("");
    setDob("");
    setAwardDate("");
    setIsFormInvalid(false);
    onOpenChange(false);
  };

  return (
    <>
      <Button onPress={onOpen} color="primary">
        Add Student
      </Button>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        isDismissable={false}
        placement="top-center"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Add Student
              </ModalHeader>
              <ModalBody>
                <Input
                  label="Name"
                  placeholder="Enter Student's Name"
                  variant="bordered"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <Input
                  label="Hours"
                  placeholder="Enter Hours Completed"
                  variant="bordered"
                  value={hours}
                  onChange={(e) => setHours(e.target.value)}
                />
                <Select 
                  label="Select Student Course" 
                  variant="bordered"
                  selectedKeys={course}
                  onSelectionChange={setCourse}
                  className="max-w-xs" >
                  {courses.map((course) => (
                    <SelectItem key={course.value} value={course.value}>
                      {course.label}
                    </SelectItem>
                  ))}
                </Select>
                <Input
                  label="Date of Birth"
                  placeholder="Select Date of Birth"
                  variant="bordered"
                  type="date"
                  value={dobTemp}
                  onChange={handleBirthDateChange}
                />
                <Input
                  label="Award Date"
                  placeholder="Select Award Date"
                  type="date"
                  variant="bordered"
                  value={awardDateTemp}
                  onChange={handleAwardDateChange}
                />
              </ModalBody>
              <ModalFooter>
                {isFormInvalid && (
                  <p className="text-danger">All fields are required.</p>
                )}
                <Button color="danger" variant="flat" onPress={onClose}>
                  Close
                </Button>
                <Button
                            isLoading={isLoading}
                            color="success"
                            onClick={handleAddStudent}
                            spinner={
                            <svg
                                className="animate-spin h-5 w-5 text-current"
                                fill="none"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                                />
                                <path
                                className="opacity-75"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                fill="currentColor"
                                />
                            </svg>
                            }
                        >
                            {isLoading ? 'Adding...' : 'Add'}
                        </Button>
                {/* <Button color="primary" onPress={handleAddStudent}>
                  Add
                </Button> */}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      {/* Error Popup */}
      <Modal
        isOpen={isErrorPopupOpen}
        onOpenChange={() => setIsErrorPopupOpen(false)}
        isDismissable={false}
        placement="top-center"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Error</ModalHeader>
              <ModalBody>
                <p className="text-danger">{errorMessage}</p>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  onPress={() => setIsErrorPopupOpen(false)}
                >
                  OK
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
