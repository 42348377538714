import { Fragment } from "react";
import Footer from "../component/layout/footer";
import FooterTwo from "../component/layout/footer-2";
import Header from "../component/layout/header";
import HeaderThree from "../component/layout/header-3";
import PageHeader from "../component/layout/pageheader";
import AchievementTwo from "../component/section/achievement-2";
import Blog from "../component/section/blog";
import Instructor from "../component/section/instructor";
import Skill from "../component/section/skill";
import Student from "../component/section/student";




const subTitle = "About Mantra Infotech Institute";
const title = "Good Qualification Services And Better Skills";
const desc = "Distinctively provide access to multifunctional users, whereas transparent processes incentivize efficient functionalities rather than extensible architecture. Communicate leveraged services and cross-platform.";

const year = "20+";
const expareance = "Years Of Experiences";



const aboutList = [
    {
        imgUrl: 'assets/images/about/icon/01.jpg',
        imgAlt: 'Skilled Instructors Icon',
        title: 'Skilled Instructors',
        desc: 'Our expert instructors are dedicated to providing top-notch education and guiding you through your learning journey, whether in offline or online classes.'
    },
    {
        imgUrl: 'assets/images/about/icon/02.jpg',
        imgAlt: 'Certificate Icon',
        title: 'Get Certificate',
        desc: 'Upon successful completion of our courses, you\'ll receive a recognized certificate to showcase your skills and knowledge gained from our offline classes.'
    },
    {
        imgUrl: 'assets/images/about/icon/03.jpg',
        imgAlt: 'Offline Classes Icon',
        title: 'Offline Classes',
        desc: 'Join our in-person offline classes and access quality education with the guidance of our skilled instructors.'
    }
];



const AboutPage = () => {
    return ( 
        <Fragment>
            <HeaderThree />
            {/* <PageHeader title={'About Our Edukon'} curPage={'About'} /> */}
            <div className="about-section style-3 padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center row-cols-xl-2 row-cols-1 align-items-center">
                        <div className="col">
                            <div className="about-left">
                                <div className="about-thumb">
                                    <img src="assets/images/about/01.jpg" alt="about" />
                                </div>
                                <div className="abs-thumb">
                                    <img src="assets/images/about/02.jpg" alt="about" />
                                </div>
                                <div className="about-left-content">
                                    <h3>{year}</h3>
                                    <p>{expareance}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="about-right">
                                <div className="section-header">
                                    <span className="subtitle">{subTitle}</span>
                                    <h2 className="title">{title}</h2>
                                    <p>{desc}</p>
                                </div>
                                <div className="section-wrapper">
                                    <ul className="lab-ul">
                                        {aboutList.map((val, i) => (
                                            <li key={i}>
                                                <div className="sr-left">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                </div>
                                                <div className="sr-right">
                                                    <h5>{val.title}</h5>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Student />
            {/* <Instructor /> */}
            <Skill />
            {/* <AchievementTwo /> */}
            {/* <Blog /> */}
            <FooterTwo />
        </Fragment>
    );
}

export default AboutPage;
 