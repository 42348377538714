import { Link } from "react-router-dom";

const title = "Build Skills With Experts Any Time Anywhere";
const desc = "Professor is Best & Perfect Venue to Grow up Your Knowledge";
const btnText = "Explore Courses";

const BannerThree = () => {
    return (
        <section className="banner-section style-3">
            <div className="container">
                <div className="section-wrapper">
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="banner-content">
                                {/* Inline style with hex color code for the title */}
                                <h2 className="title" style={{ color: '#f16126' }}>{title}</h2>
                                {/* Inline style with hex color code for the description */}
                                <p className="desc" style={{ color: '#f16126' }}>{desc}</p>
                                <Link to="/course" className="lab-btn"><span>{btnText}</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BannerThree;
