import { Fragment } from "react";
import FooterTwo from "../component/layout/footer-2";
import HeaderThree from "../component/layout/header-3";
import BannerThree from "../component/section/banner-3";

import CertificateDetails from "../component/apiFetch/certificateDetails";

const CertificateVerification = () => {
    const certificateNo = "bcni435jd";
    return (
        <Fragment>
            <HeaderThree />
            <div className="App">
                <CertificateDetails certificateNo={certificateNo} />
            </div>
            <FooterTwo />
        </Fragment>
    );
}
 
export default CertificateVerification;